import React from "react"
import Article from "../components/layout/articleRenderer"
import { StaticImage } from "gatsby-plugin-image"
import useInformation from "../hooks/use-information"
import { css } from "@emotion/react"

const contentWrapperStyle = css`
  .wp-block-gallery {
    display: none;
  }
  .wp-block-embed {
    max-width: 420px;
    margin-bottom: 30px;
  }

  .wp-block-embed > div {
    position: relative;
    padding-bottom: 75%;
    height: 0px;
    background-color: gray;
  }

  .wp-block-embed iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .wp-block-image {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    img {
      width: 100%;
      border: thin #c0c0c0 solid;
      height: auto;
    }
  }

  .videofiles {
    background: gray;
    .wp-block-image {
      padding: 0;
      height: 100%;
      img {
        height: 100% !important;
      }
    }
  }

  .wp-block-columns {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    margin: 0 -1rem;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 0;
    }
    .wp-block-column {
      width: 33%;
      height: auto;
      padding: 1rem;
      flex: 1;
      @media (max-width: 768px) {
        width: 100%;
        padding: 0 0 1rem 0;
      }
      box-sizing: border-box;
      .wp-block-file {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 90;
        font-size: 1.8rem;
        a {
          display: block;
          width: 100%;
          height: 100%;
          color: white;
          text-align: right;
        }
      }
    }
  }
  .wp-block-image {
    img {
      width: 100%;
      height: auto;
    }
  }
`

export default function Information({ data }) {
  const informationData = useInformation()

  return (
    <Article
      wordPressArticle={informationData}
      contentWrapperStyles={contentWrapperStyle}
      anchorIcon
      additionalContent={
        <StaticImage
          src="../images/information/1.jpg"
          alt="Дома"
          layout="constrained"
          css={css`
            margin-bottom: 1rem;
          `}
        />
      }
    />
  )
}
